import React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import { Box } from "@material-ui/core";
import AccordionUnitDepBeautyBar from "./depbeautybar/AccordionUnitDepBeautyBar";
import hairData from "../../data/depbeautybar/hair.json";
import nailData from "../../data/depbeautybar/nail.json";
import eyelashData from "../../data/depbeautybar/eyelash.json";
import skinData from "../../data/depbeautybar/skin.json";
import waxingData from "../../data/depbeautybar/waxing.json";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: "1.7rem",
      fontWeight: theme.typography.fontWeightBold,
    },
  })
);

const AccordionBase = () => {
  const classes = useStyles();

  return (
    <Box my={5}>
      <AccordionUnitDepBeautyBar serviceData={hairData} />
      <AccordionUnitDepBeautyBar serviceData={nailData} />
      <AccordionUnitDepBeautyBar serviceData={eyelashData} />
      <AccordionUnitDepBeautyBar serviceData={skinData} />
      <AccordionUnitDepBeautyBar serviceData={waxingData} />
    </Box>
  );
};

export default AccordionBase;
