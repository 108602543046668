import { Box, Typography } from "@material-ui/core";
import React from "react";
import ImageGallery from "react-image-gallery";

import p1 from "../../image/gallery/1.jpg";
import p2 from "../../image/gallery/2.jpg";
import p3 from "../../image/gallery/3.jpg";
import p4 from "../../image/gallery/4.jpg";
import p5 from "../../image/gallery/5.jpg";
import p6 from "../../image/gallery/6.jpg";
import p7 from "../../image/gallery/7.jpg";
import p8 from "../../image/gallery/8.jpg";
import p9 from "../../image/gallery/9.jpg";
import p10 from "../../image/gallery/10.jpg";
import p11 from "../../image/gallery/11.jpg";
import p12 from "../../image/gallery/12.jpg";
import p13 from "../../image/gallery/13.jpg";
import p14 from "../../image/gallery/14.jpg";
import p15 from "../../image/gallery/15.jpg";
import p16 from "../../image/gallery/16.jpg";
import p17 from "../../image/gallery/17.jpg";
import p18 from "../../image/gallery/18.jpg";
import p19 from "../../image/gallery/19.jpg";
import p20 from "../../image/gallery/20.jpg";
import p21 from "../../image/gallery/21.jpg";
import p22 from "../../image/gallery/20.jpg";
import p23 from "../../image/gallery/23.jpg";
import p24 from "../../image/gallery/24.jpg";
import p25 from "../../image/gallery/25.jpg";
import p26 from "../../image/gallery/26.jpg";
import p27 from "../../image/gallery/27.jpg";
import PageTitle from "../all/PageTitle";

const Gallery: React.FC = () => {
  const imageData = [
    {
      original: `${p24}`,
    },
    {
      original: `${p17}`,
    },   
    {
      original: `${p25}`,
    },
    {
      original: `${p26}`,
    },
    {
      original: `${p1}`,
    },
    {
      original: `${p2}`,
    },
    {
      original: `${p3}`,
    },
    {
      original: `${p27}`,
    },
    {
      original: `${p4}`,
    },
    {
      original: `${p5}`,
    },
    {
      original: `${p6}`,
    },
    {
      original: `${p7}`,
    },
    {
      original: `${p8}`,
    },
    {
      original: `${p9}`,
    },
    {
      original: `${p10}`,
    },
    {
      original: `${p11}`,
    },
    {
      original: `${p12}`,
    },
    {
      original: `${p13}`,
    },
    {
      original: `${p14}`,
    },
    {
      original: `${p15}`,
    },
    {
      original: `${p16}`,
    },
    {
      original: `${p18}`,
    },
    {
      original: `${p19}`,
    },
    {
      original: `${p20}`,
    },
    {
      original: `${p21}`,
    },
    {
      original: `${p22}`,
    },
    {
      original: `${p23}`,
    },
  ];

  return (
    <Box textAlign='center'>
      <Box mb={4}>
        <PageTitle title='Gallery' />
      </Box>
      <Box style={{ maxWidth: 600, marginLeft: "auto", marginRight: "auto" }}>
        <ImageGallery
          items={imageData}
          showFullscreenButton={false}
          showPlayButton={false}
          showThumbnails={false}
          showBullets={true}
          showIndex={true}
          lazyLoad={true}
        />
      </Box>
    </Box>
  );
};

export default Gallery;
