import React, { useEffect } from "react";
import "./App.css";
import { Box, Typography } from "@material-ui/core";
import Service from "./components/service/Service";
import Hero from "./components/hero/Hero";
import Gallery from "./components/gallery/Gallery";
import About from "./components/about/About";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Contact from "./components/contact/Contact";
import { useThemeMediaQuery } from "./hooks/useThemeMediaQuery";
import Social from "./components/social/Social";
import Booking from "./components/booking/Booking";

function App() {
  const { QueryTypeSize } = useThemeMediaQuery();
  let mediaQuery;

  const xsmall = QueryTypeSize("only", "xs");
  const small = QueryTypeSize("only", "sm");
  const medium = QueryTypeSize("only", "md");
  const large = QueryTypeSize("only", "lg");
  const xlarge = QueryTypeSize("only", "xl");

  if (xsmall) {
    mediaQuery = "xsmall";
  } else if (small) {
    mediaQuery = "small";
  } else if (medium) {
    mediaQuery = "medium";
  } else if (large) {
    mediaQuery = "large";
  } else if (xlarge) {
    mediaQuery = "xlarge";
  }

  return (
    <>
      {/* <Box
        px={1}
        style={{ position: "fixed", left: 0, top: 100, zIndex: 100000 }}
        bgcolor='white'
      >
        <Typography variant='h6'>{mediaQuery}</Typography>
      </Box> */}
      <Header />
      <Box id='hero' mb={10}>
        <Hero />
      </Box>
      <Box id='about' mt={10}>
        <About />
      </Box>
      <Box my={15}>
        <Social />
      </Box>
      <Box id='gallery' my={10}>
        <Gallery />
      </Box>
      <Box id='service' my={15}>
        <Service />
      </Box>
      <Box id='contact'>
        <Contact />
      </Box>
      <Box id='booking'>
        <Booking />
      </Box>
      <Footer />
    </>
  );
}

export default App;
