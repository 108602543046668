import { Box, Button, Container, Typography } from "@material-ui/core";
import PageTitle from "../all/PageTitle";
import AccordionBase from "./AccordionBase";

const Service = () => {
  return (
    <Box textAlign='center'>
      <Box my={5}>
        <PageTitle title='Service' />
      </Box>
      <Button
        href='https://depbeautybar.setmore.com/services'
        target='_blank'
        variant='contained'
        size='large'
      >
        <Typography>Pricing</Typography>
      </Button>
      <Box m='auto' style={{ maxWidth: 1000 }}>
        <AccordionBase />
      </Box>
    </Box>
  );
};

export default Service;
