import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import useResponsiveFontSizes from "../../hooks/useResponsiveFontSizes";
import { useThemeMediaQuery } from "../../hooks/useThemeMediaQuery";
import heroImage from "../../image/purpleFlower.jpg";
import text from "../../textInsert/text.json";

const useStyles = makeStyles({
  container: {
    textAlign: "center",
    height: "700px",
    overflow: "hidden",
    position: "relative",
  },
  images: {
    width: "100%",
    height: "100%",
    position: "absolute",
    // background: `linear-gradient(to right, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.7) ), url(${purpleFlower})`,
    backgroundImage: `url(${heroImage})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  messageContainer: {
    zIndex: 1,
    position: "absolute",
    bottom: "20%",
    right: 10,
  },
  messageContainerSx: {
    zIndex: 1,
    position: "absolute",
    bottom: "15%",
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",

    width: "100%",
    textAlign: "center",

    // marginRight: "auto",
  },
  address: {
    backgroundColor: "rgba(255, 255, 255, 0.0)",
    lineHeight: "normal",
    // fontFamily: "Open Sans, sans-serif",
    color: "white",
    textAlign: "center",
    textTransform: "uppercase",
  },
  name: {
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    lineHeight: "normal",
    // fontFamily: "Parisienne, cursive",
    color: "black",
    fontWeight: "bold",
    borderRadius: 10,
    padding: 5,
    textTransform: "capitalize",
    fontFamily: `${text.fontFamily}`,
  },
  phoneBox: {
    backgroundColor: "green",
    display: "inline-block",
    marginTop: 20,
  },
  nodecor: {
    color: "white",
  },
  titleContainerSx: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  titleContainer: {},
});
const Hero: React.FC = () => {
  const { ResponsiveFontSizeWrapper } = useResponsiveFontSizes();
  const classes = useStyles();
  const { QueryTypeSize } = useThemeMediaQuery();

  let screenSizeOffsSet = () => {
    if (QueryTypeSize("down", "sm")) {
      return classes.messageContainerSx;
    } else {
      return classes.messageContainer;
    }
  };

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.images} />
        <Box className={screenSizeOffsSet()}>
          <ResponsiveFontSizeWrapper>
            <Box
              className={
                QueryTypeSize("down", "sm")
                  ? classes.titleContainerSx
                  : classes.titleContainer
              }
            >
              <Typography variant='h2' className={classes.name}>
                {text.name}
              </Typography>
            </Box>
          </ResponsiveFontSizeWrapper>
          <Box my={2}>
            <Typography variant='h4' className={classes.address}>
              {"\u00A0"} {text.city}, {text.state}
            </Typography>
          </Box>
          <Box textAlign='center'>
            <Button variant='contained' size='large'>
              <a
                href={`${text.callNow}`}
                style={{ textDecoration: "none", color: "black" }}
              >
                <Typography variant='h5'>CALL {text.phone}</Typography>
              </a>
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Hero;
