import { Box, Container, Typography } from "@material-ui/core";
import React from "react";
import text from "../../textInsert/text.json";
const Footer: React.FC = () => {
  return (
    <Box bgcolor='black' color='white' py={3}>
      <Container>
        <Box>
          <Box>
            <Typography variant='h4'>{}</Typography>
          </Box>

          <Box>
            <Typography variant='h6' style={{ textTransform: "capitalize" }}>
              {text.name}
            </Typography>
          </Box>
          <Box>
            <Typography variant='h6' style={{ textTransform: "capitalize" }}>
              {text.address}
              <br />
              {text.city}, {text.state} {text.zipcode}
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
