import React from "react";
import { Box, Link, Typography } from "@material-ui/core";
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import { ReactComponent as YelpSVG } from "./image/yelp-icon.svg";

import { socialText } from "./socialText";
import PageTitle from "../all/PageTitle";

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const Social = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box bgcolor='whitesmoke'>
        <Box py={6} textAlign='center'>
          <PageTitle title='Social Media' />
          <Box p={3}>
            <Box textAlign='center' pb={4}>
              <Link
                href={socialText.facebook}
                color='inherit'
                rel='noopener'
                target='_blank'
                underline='none'
              >
                <FacebookIcon style={{ color: "#4267B2", fontSize: "5rem" }} />
              </Link>
              <Link
                href={socialText.instagram}
                color='inherit'
                rel='noopener'
                target='_blank'
                underline='none'
              >
                <InstagramIcon style={{ color: "#833AB4", fontSize: "5rem" }} />
              </Link>
              <Link
                href={socialText.yelp}
                color='inherit'
                rel='noopener'
                target='_blank'
                underline='none'
              >
                <YelpSVG
                  style={{
                    color: "#833AB4",
                    height: 70,
                    width: 70,
                    marginLeft: 8,
                    paddingBottom: 7,
                  }}
                />
              </Link>
            </Box>
            <Box mx='auto' maxWidth={600}>
              <Typography variant='h6'>
                Connect with Dep Beauty Bar through your favorite social media.
                Check for latest news. Read amazing customer reviews. View more
                pictures.
              </Typography>
            </Box>
          </Box>{" "}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Social;
