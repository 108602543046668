import { Box, Grid } from "@material-ui/core";
import React from "react";
import frontdoor from "../../image/frontdoor.jpg";
import Text from "./Text";

const About: React.FC = () => {
  return (
    <Box>
      <Grid container justify='center' alignItems='center'>
        <Grid item xs={12} sm={12} md={6} style={{ textAlign: "center" }}>
          <Box
            m='auto'
            style={
              {
                // overflow: "hidden",
                // maxWidth: 600,
                // maxHeight: 500,
              }
            }
          >
            <img
              src={frontdoor}
              alt='flower'
              style={{
                objectPosition: "center",
                maxWidth: "80%",
                border: "7px solid lightgray",
                margin: 5,
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Text />
        </Grid>
      </Grid>
    </Box>
  );
};

export default About;
