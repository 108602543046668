import { Typography } from "@material-ui/core";
import { Title } from "@material-ui/icons";
import React from "react";

interface titleProp {
  title: string;
}

const PageTitle: React.FC<titleProp> = ({ title }) => {
  return (
    <Typography
      variant='h1'
      style={{ fontFamily: "Parisienne, cursive", fontWeight: "bold" }}
    >
      {title}
    </Typography>
  );
};

export default PageTitle;
