import { Box, Grid, Typography } from "@material-ui/core";
import text from "../../textInsert/text.json";
import PageTitle from "../all/PageTitle";
const Text = () => {
  return (
    <Box mt={2}>
      <Grid container justify='center' alignItems='center'>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Box my={3}>
            <PageTitle title='About' />
          </Box>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Typography variant='h5'>{text.motto}</Typography>
          <Box p={3} textAlign='center'>
            <Typography variant='h5'>{text.maintext}</Typography>
            <Typography variant='subtitle1'>{text.subtext}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Text;
